var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t(_vm.pageTitle) } },
    [
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-form-model",
            {
              ref: "theForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-col": { span: 4 },
                "wrapper-col": { span: 14 },
                "label-align": "left"
              }
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t("lbl_code"), prop: "code" } },
                [
                  _c("a-input", {
                    attrs: {
                      "allow-clear": "",
                      placeholder: _vm.$t("lbl_type_here")
                    },
                    model: {
                      value: _vm.form.code,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "code", $$v)
                      },
                      expression: "form.code"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t("lbl_key"), prop: "key" } },
                [
                  _c("a-input", {
                    attrs: {
                      "allow-clear": "",
                      placeholder: _vm.$t("lbl_type_here")
                    },
                    model: {
                      value: _vm.form.key,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "key", $$v)
                      },
                      expression: "form.key"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t("lbl_type"), prop: "type" } },
                [
                  _c("CSelectTranslatorType", {
                    model: {
                      value: _vm.form.type,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "type", $$v)
                      },
                      expression: "form.type"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "div",
            { staticClass: "text-right" },
            [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleBack } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                  ]),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.loading.submit,
                        icon: "save"
                      },
                      on: { click: _vm.validateForm }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }