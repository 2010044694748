

































































import MNotificationVue from "@/mixins/MNotification.vue";
import { Mode } from "@/models/enums/global.enum";
import { CreateCodeTranslator } from "@/models/interface-v2/code-translator.interface";
import { IOption } from "@/models/interface-v2/common.interface";
import { codeTranslatorServices } from "@service/code-translator.service";
import { FormModel } from "ant-design-vue";
import Vue from "vue";
export default Vue.extend({
  name: "TranslatorBatchForm",
  components: {
    CSelectTranslatorType: () => import("@/components/shared/select-translator-type/CSelectTranslatorType.vue"),
  },
  mixins: [
    MNotificationVue,
  ],
  data() {
    return {
      form: {
        code: "",
        key: "",
        type: "",
      },
      rules: {
        code: [{ required: true, message: () => this.$t("lbl_validation_required_error") }],
        key: [{ required: true, message: () => this.$t("lbl_validation_required_error") }],
        type: [{ required: true, message: () => this.$t("lbl_validation_required_error") }],
      },
      dtOpt: {
        type: [] as IOption[],
      },
      loading: {
        submit: false,
      },
      codeId: "",
    };
  },
  computed: {
    isModeCreate(): boolean {
      return this.$route.meta.mode === Mode.CREATE;
    },
    isModeEdit(): boolean {
      return this.$route.meta.mode === Mode.EDIT;
    },
    isModeDetail(): boolean {
      return this.$route.meta.mode === Mode.DETAIL;
    },
    pageTitle(): string {
      if (this.isModeCreate) return "lbl_create_batch_translator";
      if (this.isModeEdit) return "lbl_edit_batch_translator";
      if (this.isModeDetail) return "lbl_view_batch_translator";
      return "lbl_batch_translator";
    }
  },
  created(): void {
    if (this.isModeEdit || this.isModeDetail && this.$route.params.id) {
      this.codeId = this.$route.params.id;
      this.getDetail(this.codeId);
    }
  },
  methods: {
    validateForm(): void {
      const $form = this.$refs.theForm as FormModel;
      $form.validate((valid: boolean) => {
        if (valid) {
          if (this.codeId) {
            this.handleUpdate();
          } else {
            this.handleCreate();
          }
        } else {
          this.showNotifValidationError();
        }
      });
    },
    async handleCreate(): Promise<void> {
      try {
        this.loading.submit = true;
        const payload: CreateCodeTranslator = {
          ...this.form
        };
        const { id } = await codeTranslatorServices.create(payload);
        this.$router.replace({ name: "settings.translatorbatch.edit", params: { id } });
        this.showNotifSuccess("notif_create_success");
      } catch (error) {
        this.showNotifError("notif_create_fail");
      } finally {
        this.loading.submit = false;
      }
    },
    async handleUpdate(): Promise<void> {
      try {
        this.loading.submit = true;
        const payload: CreateCodeTranslator = {
          ...this.form
        };
        const { id } = await codeTranslatorServices.update(this.codeId, payload);
        this.getDetail(id);
        this.showNotifSuccess("notif_update_success");
      } catch (error) {
        this.showNotifError("notif_update_fail");
      } finally {
        this.loading.submit = false;
      }
    },
    handleBack(): void {
      this.$router.push({ name: "settings.translatorbatch" });
    },
    async getDetail(docId: string): Promise<void> {
      try {
        const { id, ...res } = await codeTranslatorServices.getDetail(docId);
        this.codeId = id;
        this.form = res;
      } catch (error) {
        this.showNotifError("notif_process_fail");
      }
    }
  }
});
